import { template as template_f287f5eb78cc4da4b43d4e68cd8acdca } from "@ember/template-compiler";
const FKLabel = template_f287f5eb78cc4da4b43d4e68cd8acdca(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

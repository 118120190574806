import { template as template_ed24a5f5dc564526957d3072d24f34bd } from "@ember/template-compiler";
const FKText = template_ed24a5f5dc564526957d3072d24f34bd(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;

import { template as template_6501503e018b4fbf8ea6dca578b33b73 } from "@ember/template-compiler";
const FKControlMenuContainer = template_6501503e018b4fbf8ea6dca578b33b73(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
